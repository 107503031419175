.profile__title {
  color: $base;
  margin: 0;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  letter-spacing: 0.3px;
}

th.wraps-profile-title {
  padding-top: 0;
}

.profile-actions {
  margin-bottom: 2rem;
}

.profile-header {
  margin-bottom: 2rem;

  .profile-header__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin-bottom: 1rem;
  }

  .profile-header__title {
    h1 {
      font-weight: normal;
      margin: 0;
      margin-bottom: 0.25rem;
      color: $darkGrey;
    }
  }

  .profile-header__details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    h4 {
      text-transform: capitalize;
      margin: 0;
      white-space: nowrap;
    }
  }
}

.profile-details {
  .profile-details__title {
    margin-bottom: 1.5rem;
  }

  .profile-details__grid-wrapper {
    display: "flex";
    .profile-details__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
  }
}

.profile-footer,
.profile-details {
  border-top: 1px solid $keyline;
  padding-top: 3rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 4rem;
}

.profile-footer {
  table {
    margin-top: 1rem;
  }
  tr {
    border-top: 1px solid $keyline;
  }

  tr:last-child {
    border-bottom: 1px solid $keyline;
  }
  td {
    font-size: 14px;
    padding-left: 0;
  }
  td:last-child {
    text-align: right;
  }
}

.user-sector__dropdown {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1 / span 1;
  column-gap: 2rem;
}

.user-sector__dropdown-sub {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 2 / span 2;
  column-gap: 2rem;
}

.user-sector__custom-label {
  position: absolute;
  left: 1.2rem;
  top: 50%;
  z-index: 1;
  background: white;
  transform: translateY(-50%);
}

.user-profile-meta-terms-list {
  list-style: none;
  padding-left: 0;
  margin-top: 0;

  li {
    display: inline;
    float: left;
    background-color: $keyline;
    border-radius: 0.667em;
    padding: 0.33em 0.667em;
    color: #34332d;
  }
}

div[data-conditional="us_based"] {
  padding-left: 1.8em;
  padding-bottom: 0.8em;
  border-left: 2px solid $midGrey;
  border-bottom: 2px solid $midGrey;
  border-radius: 0 0 0 1em;
}

.userdata-table {
  table-layout: auto;
  width: auto;
  margin-top: 0.2rem;
  margin-bottom: 1rem;

  caption {
    text-align: left;
  }

  th,
  td {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
  }

  thead th:first-of-type,
  tbody td:first-of-type {
    padding-left: 0;
  }
}
