.pagination {
  list-style: none;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
  }
}

.page-item {
  &.active {
    font-weight: bolder;
    text-decoration: underline;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.page-link {
  padding: 0 ms(-6);
}
