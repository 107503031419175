h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6 {
  font-weight: 300;
  margin-top: 0;
  display: block;

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
.h1 {
  color: $darkestGrey;
  font-size: ms(4);
  font-weight: 700;
  margin-bottom: ms(-4);
}

h2,
.h2 {
  color: $darkGrey;
  margin-bottom: 0;
}

h3,
.h3 {
  color: $darkGrey;
  font-size: ms(1);
  margin-bottom: ms(-2);

  &:last-child {
    margin-bottom: ms(-2);
  }
}

h4,
.h4 {
  color: $darkGrey;
  font-size: ms(2);
  margin-bottom: ms(-1);
}

h5,
.h5 {
  color: $darkGrey;
  font-size: ms(0);
  margin-bottom: 0;
}

h6,
.h6 {
  color: $darkGrey;
  font-size: ms(0);
  margin-bottom: 0;
}

p {
  margin-top: 0;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
}

time {
  color: $darkGrey;
}

.uppercase {
  text-transform: uppercase;
}

.bolder {
  font-weight: bolder;
}

.bold {
  font-weight: 700;
}

.link--active {
  color: $accent-blue;
}

.highlight {
  color: $accent-yellow;
  font-size: 120%;
  font-weight: bolder;
}

.link--active,
.progress {
  color: $accent-blue;
}

.danger {
  color: $danger;
}

.success {
  color: $success;
}

.dark-grey {
  color: $darkGrey;
}
