// Survey page builder
#survey-page-builder {
  .form__input {
    border-bottom: 1px solid $keyline;
    display: block;
    padding-top: ms(9);
    padding-bottom: ms(9);
    position: relative;
    transition: all 0.7s;

    .form__input {
      padding-top: ms(7);
      padding-bottom: ms(7);
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover,
    &:focus {
      .form__input_move_field {
        opacity: 1;
      }
    }
  }
}

.form__input_move_field {
  background-color: $white;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  transition: opacity 0.3s;
  opacity: 0;
  z-index: 1;
}

.form__input_move_field--down {
  right: 0;
  padding: ms(-2) 0 ms(-2) ms(-2);
}

.form__input_move_field--up {
  left: 0;
  padding: ms(-2) ms(-2) ms(-2) 0;
}

.form__input--moved {
  animation-name: highlightMove;
  animation-duration: 2s;
}

@keyframes highlightMove {
  from {
    background-color: $info;
  }
  to {
    background-color: $white;
  }
}

.survey-page-builder__pageSelector {
  max-width: 58.3333%;
  overflow: hidden;

  @include respond-min($breakpoint-desktop) {
    max-width: 75%;
  }
}

// Survey question builder transitions
.form__input_list-enter,
.form__input_list-leave-active {
  opacity: 0;
}

.form__input_list-enter {
  transform: translateX(-30px);
}

.form__input_list-leave-active {
  transition: all 0.7s;
  transform: translateX(30px);
}

// Survey question types - client
.checkboxQuestion,
.dropdownQuestion,
.radioQuestion,
.rankingQuestion {
  .form__input--with-actions {
    &:before {
      content: "";
      position: absolute;
      top: 45%;
      left: 0;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
}

.checkboxQuestion,
.radioQuestion {
  .form__input--with-actions {
    &:before {
      border: 2px solid $offWhite;
      width: 17px;
      height: 17px;
    }
  }
}

.rankingQuestion {
  .form__input--with-actions {
    display: flex;
    input[type="text"].form__input_label {
      padding-left: 0.4822530864em;
    }
  }
  .form__input-index {
    padding: 0.4822530864em 0.4822530864em 0.4822530864em 0;
  }
}

.dropdownQuestion {
  .form__input--with-actions {
    &:before {
      border: 1px solid $offWhite;
      width: 17px;
      height: 1px;
    }
  }
}

.radioQuestion {
  .form__input--with-actions {
    &:before {
      border-radius: 50%;
    }
  }
}

.form__input_clickable_icon {
  content: "";
  pointer-events: auto;
  position: absolute;
  top: 66%;
  left: 0;
  transform: translateY(-50%);
}

.form__input_icon--number {
  border: 2px solid $offWhite;
  border-radius: 50%;
  box-sizing: content-box;
  color: $darkGrey;
  display: inline-block;
  font-size: ms(-1);
  text-align: center;
  width: 17px;
  height: 16px;
  padding: 3px;
  vertical-align: middle;
}

// Survey question types - payer
// Likert scale
.form__input--likert {
  fieldset {
    @include respond-min($breakpoint-desktop) {
      flex: 1 0 auto;
    }
  }

  li {
    display: inline-block;
    flex: 1 0 auto;
    vertical-align: top;

    &:last-of-type {
      margin-right: 0;
    }

    @include respond-min(370px) {
      margin-right: 4px;
    }

    @include respond-min($breakpoint-tablet) {
      margin-right: ms(-1);
    }
  }

  label {
    margin-bottom: 0;

    &:hover,
    &:focus {
      color: $accent-blue;

      .form__input--likert-value,
      .form__input--likert-number {
        color: $accent-blue;
      }

      .form__input--likert-number {
        border-color: $accent-blue;
      }
    }
  }

  input[type="radio"] {
    @include sr();

    &:checked {
      + .form__input--likert-number {
        background-color: $accent-blue;
        border-color: $accent-blue;
        color: $white;
      }

      ~ .form__input--likert-value {
        color: $accent-blue;
      }
    }
  }
}

.form__input--likert-number,
.form__input--likert-value {
  pointer-events: none;
}

.form__input--likert-number {
  background-color: $white;
  border-radius: $border-radius;
  border: 2px solid $midGrey;
  padding: ms(-3) 0.35em;
  text-align: center;
  transition: all 0.3s;

  @include respond-min(370px) {
    padding-left: 0.45em;
    padding-right: 0.45em;
  }

  @include respond-min($breakpoint-tablet) {
    padding-left: ms(0);
    padding-right: ms(0);
  }
}

// Checkbox and radio grid
.form__input--checkboxes,
.form__input--radios {
  li {
    display: block;
    float: left;
    margin-right: ms(6);

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.rankingQuestionInput {
  display: flex;
  align-items: center;
  padding-bottom: 0.5em;
  cursor: grab;

  .form__input-index {
    padding-bottom: 0.5em;
    padding-right: 0.5rem;
    border-bottom: 1px solid transparent;
  }
}

.ranking-label {
  display: block;
  padding-bottom: 0.5em;
  border-bottom: 1px solid $keyline;
  width: 100%;
}

.tableQuestion {
  th span {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .table-question__input {
    background-color: transparent;
  }

  .table-question__cell {
    border: 1px solid $keyline;
  }

  .table-question__cell:first-child {
    border-left: 0;
  }

  .table-question__cell:last-child {
    border-right: 0;
  }
}

.table-input__label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.survey-table-input {
  th {
    text-align: center;
  }
}
