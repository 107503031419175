.footer {
  background-color: #44423c;
  color: $offWhite;
  padding-top: ms(4);
  padding-bottom: ms(4);
  box-shadow: inset 0 15px 10px -10px rgba(0,0,0,0.29);
  border-top: 1px solid black;

  a {
    padding: 0 10px;

    &:last-of-type {
      padding-right: 0;
    }
  }
}
