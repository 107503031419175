.flash {
  border-bottom: 1px solid currentColor;
  border-top: 1px solid currentColor;
  color: currentColor;

  .btn {
    border: 1px solid currentColor;
    color: currentColor;
  }
}

.flash--info {
  background-color: $info;
  color: $accent-blue;
}

.flash--danger {
  background-color: $danger-light;
  color: $danger;
}

.flash--success {
  background-color: $success-light;
  color: $success;
}
