main {
  float: left;
  padding: ms(6) 10px;
  width: 100%;

  @include respond-min($breakpoint-desktop) {
    padding: ms(8) 0;
  }
}

section {
  float: left;
  padding: ms(6) 0;
  width: 100%;

  &:first-of-type {
    padding-top: 0;
  }

  @include respond-min($breakpoint-desktop) {
    padding: ms(8) 0;

    &:first-of-type {
      padding-top: 0;
    }
  }
}

.section--keyline {
  border-bottom: 1px solid $keyline;
}

.sectionGroup section:last-of-type {
  border-bottom: 0
}
