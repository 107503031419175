[data-tooltip]:not([data-tooltip='']) {
  position: relative;

  &:before,
  &:after {
    color: $white;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s 0.25s;
    position: absolute;
    font-size: 0.9rem;
  }

  &:after {
    content: attr(data-tooltip);
    background: $darkestGrey;
    padding: ms(-6) ms(-4);
    border-radius: $border-radius;
    pointer-events: none;
    width: 250px;
    text-align: center;
    z-index: 2;
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
  }

  &:hover {
    &:after,
    &:before {
      visibility: visible;
      opacity: 1;
    }
  }
}

.tooltip--up[data-tooltip] {
  &:before,
  &:after {
    transform: translate(-50%, 0.5rem);
    bottom: 100%;
    left: 50%;
  }

  &:before {
    border-left: ms(-6) solid transparent;
    border-right: ms(-6) solid transparent;
    border-top: ms(-6) solid $darkestGrey;
    margin-bottom: ms(-6);
  }

  &:after {
    margin-bottom: calc((#{ms(-6)} * 2) - 1px);
  }

  &:hover {
    &:after,
    &:before {
      transform: translate(-50%, 0);
    }
  }
}

.tooltip--right[data-tooltip] {
  &:before,
  &:after {
    transform: translate(-50%, 0.5rem);
    bottom: 100%;
    left: 50%;
  }

  &:before {
    border-left: ms(-6) solid transparent;
    border-right: ms(-6) solid transparent;
    border-top: ms(-6) solid $darkestGrey;
    margin-bottom: ms(-6);
  }

  &:after {
    margin-bottom: calc((#{ms(-6)} * 2) - 1px);
  }

  @include respond-min($breakpoint-tablet) {
    &:before,
    &:after {
      bottom: 50%;
      left: 100%;
      transform: translate(0, 50%);
      margin-bottom: 0;
    }

    &:before {
      border-left: 0;
      border-right: ms(-6) solid $darkestGrey;
      border-top: ms(-6) solid transparent;
      border-bottom: ms(-6) solid transparent;
      margin-left: ms(-6);
    }

    &:after {
      margin-left: calc((#{ms(-6)} * 2) - 1px);
    }
  }
}
