.pill {
    background-color: $beige;
    border-radius: 15px;
    height: 20px;
    position: relative;
    margin: ms(-6) 0;
    min-width: 120px;
    overflow: hidden;
    width: 100%;
}

.pill__inner {
    animation: fill 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    background-color: $accent-yellow;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
}

@include fill();
