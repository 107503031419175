.progressBar {
  background-color: darken($midGrey, 10%);
  height: 5px;
}

.progressBar__inner {
  animation: fill 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  background-color: $accent-yellow;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}
