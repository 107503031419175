.breadcrumb {
  a {
    display: inline-block;
    padding: ms(1) 0;
    position: relative;

    &:not(:first-of-type):before {
      background-image: url('../../img/icons/chevron--right-white.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      content: '';
      display: inline-block;
      height: 0.6em;
      margin: -0.1em ms(-3) 0;
      opacity: 0.5;
      vertical-align: middle;
      width: 0.6em;
    }

    // :after element is the bottom underline. Different width on first of type
    // as that doesn't have an SVG :before element
    &:first-of-type:after {
      width: 100%;
    }

    &:after {
      bottom: 0;
      content: '';
      height: 5px;
      opacity: 0;
      position: absolute;
      right: 0;
      transition: all 0.3s;
      width: calc(100% - 28.2px);
    }

    &:hover:after {
      background-color: darken($beige, 10%);
      opacity: 1;
    }

    &.active {
      color: $darkGrey;

      &:after {
        background-color: $accent-yellow;
        opacity: 1;
      }
    }
  }

  + .btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @include respond-min($breakpoint-tablet) {
    + .btn {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.breadcrumb__seperator {
  margin: 0 ms(2);
}
