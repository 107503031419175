@mixin sr() {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin caps() {
  text-transform: uppercase;
  font-weight: 700;
}
