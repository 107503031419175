.survey-builder {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: min-content auto;
  border-bottom: 1px solid $midGrey;
}

.sb__pages {
  grid-row: 2;
  grid-column: 1 / span 6;
  border-top: 1px solid $midGrey;
  border-right: 1px solid $midGrey;
  // padding: 2rem;
}

.sb__question {
  grid-row: 2;
  grid-column: 7 / span 14;
  border-top: 1px solid $midGrey;
}

.sb__pages-header,
.sb__question-header {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 2rem;
  border-bottom: 1px solid $midGrey;
}

.sb__pages-header {
  gap: 1rem;
}

.sb__question-header {
  gap: 3rem;
  align-items: center;
}

.sb__question-header__details {
  display: flex;
  gap: 3rem;
  align-items: flex-end;
  span {
    line-height: 1.5em;
  }
}

.sb__pages-list-item {
  position: relative;
  border-bottom: 1px solid $midGrey;
  padding-bottom: 1rem;
}

.sb__question-inner {
  padding: 2rem;
  padding-bottom: 4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sb__pages-list-item__icon {
  position: absolute;
  left: 0.5rem;
  display: none;
}

.sb__pages-list-item__delete-icon {
  position: absolute;
  right: 1rem;
  top: calc(50% - 2px);
  display: none;
  transform: translateY(-50%);
  cursor: pointer;
}

.sb__pages-list-item__header:hover {
  background-color: $lightGrey;
  .sb__pages-list-item__icon,
  .sb__pages-list-item__delete-icon {
    display: block;
  }
}

.sb__pages-list-item__question:hover {
  .sb__pages-list-item__icon {
    display: block;
  }
}

.sb__pages-list-item__header--selected {
  background-color: $lightGrey;
}

.sb__pages-list-item__header {
  position: relative;
  padding: 1rem 2rem;
}

.sb__pages-list-item__question {
  position: relative;
  padding: 0.5rem 0 0.5rem 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  &:not(.sb__pages-list-item__question--none) {
    &:hover,
    &.sb__pages-list-item__question--selected {
      background-color: $lightGrey;
      border-top: 1px solid $midGrey;
      border-bottom: 1px solid $midGrey;
      .sb__pages-list-item__delete-icon {
        display: block;
      }
    }
  }
}

.sb__pages-list-item__question--error {
  color: red;
  .sb__pages-list-item__question__index,
  .sb__pages-list-item__question__icon {
    border-color: red;
  }
}

.sb__pages-list-item__question__number {
  display: flex;
}

.sb__pages-list-item__question__index,
.sb__pages-list-item__question__icon {
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $darkGrey;
  height: 1.6rem;
  width: 1.6rem;
}

.sb__pages-list-item__question__icon {
  min-height: 100%;
  border-left: 1px solid transparent;
}

.sb__question-actions {
  margin-top: auto;
  margin-left: auto;
  padding: 1rem;
  padding-right: 0;
  .sb__question-action {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      margin-bottom: 1px;
    }
  }
}

#save-survey {
  margin-right: 1rem;
}
