.modal-body-no-scroll {
  margin-right: 0 !important; // addresses an issue where Chrome adds margin to the body when the popup is open
  overflow-y: auto;
}

.modal {
  background: rgba($black, 0.7);
  cursor: pointer;
  display: none;
  z-index: 4;
}

.modal--is-visible {
  display: flex;
  .modal__dialog {
    transform: translateY(0) scale(1);
  }
}

.modal__body {
  padding: 0;
}

.modal__body > div,
.modal__header {
  padding: ms(1);
}

.modal__dialog {
  cursor: auto;
  max-width: ms(20);
  border-radius: $border-radius;
  transform: translateY(1rem) scale(0.95);
  transition: all 0.3s;
}

.modal__title {
  margin-right: auto;
}

.modal__content {
  min-height: ms(11);
}

.modal__confirm {
  background: $white;
  border-top: 1px solid $keyline;
  float: left;
  width: 100%;

  .btn {
    float: right;
  }

  .btn--muted {
    margin-right: 10px;
  }
}

.modal__close {
  background-image: url('../../img/icons/cross.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  width: ms(1);
  height: ms(1);
  flex-shrink: 0;
  margin-left: ms(1);

  &:focus {
    outline: 1px solid $accent-blue;
  }
}

.modal__close-text {
  @include sr();
}

.modal__header {
  border-color: $keyline;
  background: $white;
}
