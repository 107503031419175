.card {
  a:not(.btn):hover {
    color: $accent-blue;
  }
}

.card--keyline {
  border: 1px solid $keyline;
}

.card--keyline-dark {
  border: 1px solid darken($keyline, 10%);
}

.card--border-radius {
  border-radius: $border-radius;
}

.card--danger {
  background: $danger-light;
  border: 1px solid currentColor;
  color: $danger;
  grid-row: 1;
  grid-column: 5 / span 12;
  margin-bottom: 1.5rem;
}

.card__inner {
  background-color: $white;
  border: 1px solid $white;
  padding: ms(2);
  position: relative;
  transition: all 0.3s;
  width: 100%;

  h3 {
    display: inline-block;
    margin-bottom: 0;
  }

  > div {
    @include respond-min($breakpoint-desktop) {
      flex-wrap: nowrap;
    }
  }
}

.card__inner--dark {
  background-color: $lightestGrey;
}

.card__inner--hover {
  &:hover {
    box-shadow: 0px 3px 7px 1px rgba(156, 156, 156, 0.3);
  }
}

.card--active {
  box-shadow: 0px 3px 7px 1px rgba(156, 156, 156, 0.3);
}

.card__inner--info {
  background-color: $info;
  border: 1px solid currentColor;
  border-radius: .2em;
  color: $accent-blue;
  padding: ms(0) ms(2);
  width: auto;

  .card__title {
    display: block;

    @include respond-min($breakpoint-desktop) {
      max-width: none;
      padding-right: 0;
    }
  }
}

.card__section--keyline {
  border-top: 1px solid $keyline;
  padding-top: ms(2);
}

.card__section--keyline-bottom {
  border-bottom: 1px solid $keyline;
  padding-bottom: ms(2);
}

.card__title {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  @include respond-min($breakpoint-tablet) {
    align-items: center;
    flex-direction: row;
    width: auto;
  }

  @include respond-min($breakpoint-desktop) {
    max-width: 70%;
    padding-right: ms(0);
  }
}

.card__title_status {
  @include respond-min($breakpoint-desktop) {
    min-width: 170px;
  }
}

.card__title_status_pill {
  border: 1px solid $midGrey;
  border-radius: 15px;
  color: lighten($darkGrey, 15%);
  display: inline-block;
  padding: ms(-6) ms(0);
  white-space: nowrap;
}

.card__title_status_dot {
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
}

@each $name, $value in $statusMap {
  .card--#{$name} {
    border-left: 4px solid $value;
  }

  .card__title_status_dot--#{$name} {
    background-color: $value;
  }
}

.card__meta {
  border-top: 1px solid $keyline;
  margin-top: ms(2);
  padding-top: ms(2);
  position: relative;
  width: 100%;

  a,
  button[type="submit"] {
    display: inline-block;
    padding: 5px 10px !important;
  }

  @include respond-min($breakpoint-desktop) {
    border-top: none;
    display: flex;
    justify-content: flex-end;
    padding-left: ms(0);
    padding-top: 0;
    margin-top: 0;
    white-space: nowrap;
    width: auto;
  }
}

.card__rollover {
  transition: all 0.3s;

  @include respond-max($breakpoint-mobile) {
    flex-direction: column;
  }
}

.card__rollover--in {
  opacity: 0;
  pointer-events: none;
}

.card__rollover--out {
  &:not(:only-child) {
    position: absolute;
  }

  @include respond-min($breakpoint-desktop) {
    top: 50%;
    right: 0;

    &:not(:only-child) {
      transform: translateY(-50%);
    }

    > div {
      margin-left: 20px;

      &:empty {
        display: none;
      }
    }
  }
}

.card--active,
.card__inner--hover:hover {
  > * > .card__meta > .card__rollover--in {
    opacity: 1;
    pointer-events: auto;
  }
  > * > .card__meta > .card__rollover--out {
    opacity: 0;
    pointer-events: none;
  }
}

.card .card__scrolldown {
  display: none;
}

.card .card__scrolldown--visible {
  display: block;
}
