button {
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn {
  background-color: transparent;
  border: none;
  appearance: none;
  line-height: 1.4;
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  border-radius: $border-radius;
  font-weight: 300;
  padding: ms(-4) ms(-1);
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    pointer-events: none;
  }
}

.btn--primary {
  background-color: $accent-yellow;
  border: 1px solid $accent-yellow;
  color: $darkestGrey;

  &:hover,
  &:focus {
    background-color: darken($accent-yellow, 10%);
  }
}

.btn--secondary {
  background-color: $lightGrey;
  border: 1px solid $offWhite;
  color: $darkGrey;

  &:hover,
  &:focus {
    background-color: darken($lightGrey, 2%);
  }
}

.btn--tertiary {
  background-color: transparent;
  border: 1px solid $offWhite;
  color: $darkGrey;
}

.btn--danger {
  background-color: $danger;
  border: 1px solid $danger;
  color: $white;

  &:hover,
  &:focus {
    background-color: darken($danger, 10%);
  }
}

.btn--muted {
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: inherit;
  font-weight: inherit;
  outline: none;

  &:hover,
  &:focus {
    background-color: $lightGrey;
    border-color: $lightGrey;
  }
}

.btn--large {
  font-size: ms(1);
}

.btn--slim {
  padding: ms(-6) ms(-3);
}

.btn--center {
  text-align: center;
}

.btn--full-width {
  display: block;
  width: 100%;
}

.btn--icon {
  padding: 4px;
}

@include respond-max($breakpoint-mobile) {
  .btn--full-width-mobile {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.btn--tooltip,
tr .btn--tooltip {
  opacity: 1;
}

.btn--filters {
  font-size: 14px;
  border: 2px solid $keyline;
  padding: 10px 16px;
  .sml-push-left {
    margin-right: 8px;
    margin-bottom: 1px;
  }
}
