.dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
}

.dropdown__menu {
  background: $white;
  border: 1px solid $midGrey;
  border-radius: $border-radius;
  box-shadow: 0 1px 1px 1px rgba(133, 132, 124, 0.06);
  color: $darkGrey;
  margin-top: ms(-6);
  min-width: ms(14);
  opacity: 0;
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateY(-ms(0)) scale(0.96);
  transition: all 0.3s;
  visibility: hidden;
  will-change: transform;
  z-index: 3;
}

.dropdown__menu--up {
  bottom: 100%;
  margin-top: 0;
  margin-bottom: ms(-6);
  top: auto;
}

.dropdown__menu--left {
  left: 0;
  right: auto;
}

.dropdown__menu--longer {
  min-width: ms(17);
}

.dropdown__menu--auto-width {
  min-width: 0;
}

.no-js .dropdown__trigger:focus + .dropdown__menu,
.no-js .dropdown:hover .dropdown__menu,
.dropdown--is-visible .dropdown__menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0) scale(1);
}

.dropdown--is-visible .icon--rotate {
  transform: rotate(180deg);
}

.dropdown__heading {
  background-color: $lightGrey;
  border-bottom: 1px solid $midGrey;
  margin-bottom: 0;
  padding: ms(0) ms(2);
}

.dropdown__menu_item {
  border: none;
  border-bottom: 1px solid $midGrey;
  cursor: pointer;
  display: block;
  font-weight: 300;
  padding: ms(1) ms(2);
  text-align: left;
  // vertical-align: middle;
  width: 99.8%;
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    margin-bottom: 2px;
  }

  &:hover {
    background-color: $lightGrey;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &.not-allowed:hover {
    background-color: $white;
  }
}

.dropdown__menu_item--survey {
  display: flex;
}

.dropdown__menu_item--survey_title {
  border-right: 1px solid $keyline;
  flex: 1 0 auto;
  padding-right: 10px;
}

.dropdown__menu_item--no-hover:hover {
  background-color: $white;
}

.dropdown__menu_item--shorter {
  padding-top: ms(-2);
  padding-bottom: ms(-2);
}
