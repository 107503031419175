.small {
  font-size: 80%;
}

.smaller {
  font-size: 90%;
}

.larger {
  font-size: 120%;
}

.large {
  font-size: 140%;
}
