.collapsible-panel {}

.collapsible-panel--is-open {
 .collapsible-panel__indicator {
   transform: scaleY(-1);
 }
}

.collapsible-panel__heading {
  margin-top: 0;
  margin-bottom: 0;
}

.collapsible-panel__toggle {
  display: flex;
  align-items: center;
  text-align: left;

  &:focus {
    outline: 2px solid $accent-yellow;
    outline-offset: -2px;
  }
}

.collapsible-panel__indicator {
  margin-right: ms(-4);
}

.collapsible-panel__content {
  border-left: 4px solid $lightGrey;
  max-height: 35em;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.6em;
    background-color: #fff;
    border-left: 1px solid $keyline;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $offWhite;
  }
}


