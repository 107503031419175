table,
.table {
  display: table;
  width: 100%;
}

.table--fixed {
  table-layout: fixed;
}

.table--default {
  min-height: 10rem;
  position: relative;
  tr {
    border-bottom: 1px solid $midGrey;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background-color: lighten($info, 1%);
    }
  }

  thead {
    background-color: $lightGrey;

    th {
      padding: ms(2) 10px;
    }

    tr:hover {
      background-color: $lightGrey;
    }
  }
}

td,
.table-data {
  display: table-cell;
  padding: ms(-2) 10px;
  vertical-align: middle;
}

th,
.table-head {
  display: table-cell;
  font-size: 80%;
  font-weight: 600;
  padding: ms(0) 10px;
  text-transform: uppercase;
  text-align: left;

  &.text-right,
  &.med-text-right {
    text-align: right;
  }

  &.text-center {
    text-align: center;
  }
}

.table__head--short {
  width: 20%;
}

.table__head--medium {
  width: 30%;
}

.table__head--long {
  width: 99%;
}

tr,
.table-row {
  display: table-row;

  .btn {
    font-size: 90%;
  }

  @include respond-min($breakpoint-desktop) {
    .btn {
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      .btn {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

table.table--no-style {
  tr:nth-of-type(odd) {
    background-color: transparent;
  }

  td,
  .table-data {
    padding: 5px 0;
  }

  tfoot {
    td {
      padding-bottom: 0;

      @include respond-min($breakpoint-tablet) {
        border-top: 1px solid $midGrey;
        padding-top: 20px;
      }
    }
  }

  tbody tr:last-of-type td {
    padding-bottom: 20px;
  }
}
