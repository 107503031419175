.user-database {
  display: flex;
  flex-direction: column;
}

.user-database td {
  text-transform: capitalize;
}

.user-database__filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .user-database__select-wrapper {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    .user-database__select {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 10px 12px;
      border-radius: 32px;
      border: 2px solid $keyline;
      white-space: nowrap;
    }

    select {
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -ms-appearance: none;
      min-width: 100px;
      padding: 0px;
      padding-right: 24px;

      background-size: 10px auto;
      margin-left: 2px;
    }
  }

  .user-database__search {
    margin-left: auto;
    min-width: 400px;
    input {
      border-radius: 50px;
      flex: 1 0 auto;
      font-size: 14px;
      padding: 10px 35px;
    }
  }
}

.user-database__summary {
  display: flex;
  justify-content: end;
  font-size: 0.875;
  gap: 1.25rem;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
    span {
      font-weight: 600;
    }
  }
  a {
    text-decoration: underline;
  }
}

.popover__list {
  display: flex;
  flex-direction: column;
  color: $base;
  font-size: 90%;
  a:not(:last-child) {
    margin-bottom: 6px;
  }
}

.wrapper .tooltip-inner.popover-inner {
  padding: 16px;
  border: 1px solid $keyline;
  background-color: #fff;
}

.popover__item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin: 12px 0;
  color: $base;
}

.popover__item.disabled {
  opacity: 0.5;
  cursor: default;
}

.cell--hidden {
  display: none;
}

.row--excluded {
  td {
    position: relative;
  }
  .cell--label,
  .cell--link {
    opacity: 0.4;
  }

  &:hover {
    .cell--label {
      display: none;
    }
    .cell--hidden {
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      text-transform: none;
      opacity: 1;
      white-space: nowrap;
    }
  }
}

.user-database__pagination {
  display: flex;
  gap: 0.5rem;
  margin: 1rem auto;

  a {
    opacity: 0.6;
  }

  a.selected {
    opacity: 1;
    border-bottom: 1px solid $base;
  }
}
