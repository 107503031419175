.subnav {
  background-color: $beige;

  @include respond-min($breakpoint-tablet) {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
