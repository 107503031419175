.spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;

  > div {
    animation: grow 1.2s infinite ease-in-out;
    background-color: $accent-yellow;
    display: inline-block;
    height: 100%;
    width: 6px;
  }
  .rect2 {
    animation-delay: -1.1s;
  }
  .rect3 {
    animation-delay: -1s;
  }
  .rect4 {
    animation-delay: -0.9s;
  }
  .rect5 {
    animation-delay: -0.8s;
  }
}

.spinner--table {
  position: absolute;
  width: 100%;
  margin-top: 2rem;
}

@keyframes grow {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
