.table--ledger {
  th {
    font-weight: 700;
  }

  td::before {
    color: $base;
  }
}

@include respond-min($breakpoint-desktop) {
  .table--ledger__date {
    width: 12%;
    padding-left: ms(2);
  }

  .table--ledger__desc {
    width: 35%;
  }

  .table--ledger__balance {
    padding-right: ms(2);
  }
}

@each $name, $value in $ledgerTypeMap {
  .table--ledger__type--#{$name} {
    color: $value;
  }
}
