.dropdown__menu_notifications {
  min-width: ms(15);

  @include respond-min($breakpoint-tablet) {
    right: 0;
    left: auto;
    min-width: ms(16);
  }

  @include respond-min($breakpoint-desktop) {
    min-width: ms(17);
  }
}

.notifications__container {
  max-height: ms(15);
  overflow-y: scroll;
  overflow-x: hidden;
}

.notifications__clear_all {
  cursor: auto;
  border-top: 1px solid $keyline;
}
