.not-allowed {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.cursor-initial {
  cursor: initial;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.no-wrap {
  white-space: nowrap;
}

.break-words {
  word-wrap: break-word;
  word-break: break-word;
}

.inline-block {
  display: inline-block;
}

.auto-width {
  width: auto;
}

.mobile-label {
  &:before {
    color: $base;
    content: attr(data-label);
    display: block;

    @include respond-min($breakpoint-desktop) {
      display: none;
    }
  }
}

.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
