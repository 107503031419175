body {
  background: $white;
  color: $base;
  font-family: "Helvetica", sans-serif;
  font-weight: 300;
  line-height: 1.4;
}

.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-content {
  z-index: 1;
  flex: 1 0 auto;
}

.site-content--survey main {
  padding: 0;
}
