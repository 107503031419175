.flex {
  display: flex;
  flex-wrap: wrap;
}

.inline-flex {
  display: inline-flex;
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-justify-center {
  justify-content: center;
}

.flex-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-center {
  align-items: center;
}

.flex-align-self-start {
  align-self: flex-start;
}

.flex-align-self-end {
  align-self: flex-end;
}

.flex-column {
  flex-direction: column;
}

.flex-expand {
  flex: 1 0 auto;
}

.flex-child-equal-width {
  display: inline-block;
  flex: 1 0 auto;
}

.flex-child-bottom {
  margin-top: auto;
}

@include respond-max($breakpoint-desktop) {
  .flex-reverse {
    order: -1;
  }
}
