.respondent-back-btn {
  margin-bottom: 2rem;
  font-size: 0.875rem;
}

.respondent-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;

  .respondent-overview {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    h4 {
      margin: 0;
    }
  }

  .respondent-total {
    border-color: 1px solid $keyline;
  }
}

.respondent-filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .respondent-filters__select-wrapper {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    .respondent-filters__select {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 10px 12px;
      border-radius: 32px;
      border: 1px solid $keyline;
    }

    select {
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -ms-appearance: none;
      width: 100px;
      padding: 0px;
      background-size: 10px auto;
      margin-left: 2px;
    }
  }
}

.check-cell {
  cursor: pointer;
  width: 2rem;
  .check {
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-top: -0.25em;
      width: ms(0);
      height: ms(0);
      background: $lightestGrey;
      margin-right: ms(-4);
      margin-left: 2px;
      border: 2px solid $keyline;
    }
    &.selected {
      &:before {
        background-color: $accent-blue;
        border-color: $accent-blue;
        background-image: url("../../../img/icons/tick.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 85%;
      }
    }
  }
}

.respondent-table {
  margin-top: 1rem;
  margin-bottom: 1rem;

  th {
    color: $base;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
  }

  tr {
    border-bottom: 1px solid $keyline;
  }

  tbody tr {
    font-size: 0.875rem;
    text-transform: capitalize;
    &:hover {
      background-color: lighten($lightGrey, 2%);
    }
  }
}

.respondent-allocation {
  display: flex;
  align-items: center;
  div {
    margin-right: 12px;
  }
}

.filter-control {
  select {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none;
    padding: 0px;
    background-size: 10px auto;
    // margin-left: 2px;
  }

  // position: relative;
  // .filter-control__menu {
  //   position: absolute;
  //   bottom: -16px;
  //   background-color: #fff;
  // }
}

.respondent-allocation__total {
  position: relative;
  z-index: -1;
  .respondent-allocation__total-title {
    text-transform: uppercase;
    position: absolute;
    top: -20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
    font-size: 12px;
    letter-spacing: 0.2px;
  }

  .respondent-allocation__total-requested {
    height: 39px;
    overflow: hidden;
    width: 240px;
    position: relative;
    border-radius: 0.2em;
    color: #6d6c66;
    padding: 12px;
    background-color: #f5f5f2;
    display: flex;
    align-items: center;
    z-index: 2;
    color: $darkestGrey;
  }

  .respondent-allocation__total-allocated {
    border-top-left-radius: 0.2em;
    border-bottom-left-radius: 0.2em;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #9eb3dc;
    color: #fff;
    z-index: -1;
    transition: width 0.2s ease-out;
  }
}
