header {
  background-color: #44423c;
  border-bottom: 1px solid black;
  box-shadow: inset 0px -10px 10px -5px rgba(0, 0, 0, 0.29);
}

.header__inner {
  flex-direction: column;
  justify-content: center;

  @include respond-min($breakpoint-tablet) {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.header__branding {
  width: 100%;
  text-align: center;

  @include respond-min($breakpoint-tablet) {
    float: left;
    margin-right: ms(4);
    width: auto;
  }
}

.header__nav_container {
  display: flex;

  @include respond-min($breakpoint-tablet) {
    display: block;
    width: auto;
  }
}

nav {
  ul {
    margin: 0;
    padding-left: 0;
  }
}

.nav__secondary {
  @include respond-min($breakpoint-tablet) {
    float: right;
    margin-left: auto;
  }
}

.menu__toggle {
  display: none;

  &.open {
    display: block;
  }

  @include respond-min($breakpoint-tablet) {
    display: block;

    .nav__item {
      text-align: center;
    }
  }
}

.nav__item {
  display: block;
  float: left;
}

.nav__link {
  color: $offWhite;
  display: block;
  padding: ms(-2) ms(-1);

  &:hover {
    background: darken($darkestGrey, 15%);
    text-decoration: none;
  }

  @include respond-min($breakpoint-tablet) {
    padding: ms(1);
  }
}

.nav__item--with-notifications {
  position: relative;

  &::after {
    background-color: $danger;
    border-radius: 10px;
    content: '';
    position: absolute;
    top: 40%;
    left: 53%;
    width: 7px;
    height: 7px;
  }
}
