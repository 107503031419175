[data-toggle='anchor'] {
  cursor: pointer;

  &.open {
    .icon {
      transform: rotate(180deg);
    }
  }
}

[data-toggle='target'] {
  display: none;

  &.open {
    display: block;
  }
}
