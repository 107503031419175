// Screen Reader Text. See: https://webaim.org/techniques/css/invisiblecontent/
.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.user-tags-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.user-tag {
  background-color: $beige;
  border: 1px solid #d8d7d3;
  border-radius: 0.2em;
  color: #6d6c66;
  float: left;
  line-height: 1.4;
  padding: 0.166em 0.667em;
  margin: auto 0.166em 0.33em;
  flex: 0 1 auto;

  &:before {
    color: #8a8879;
    content: "#";
    font-family: monospace;
    font-size: 1.3em;
    border-right: 1px solid #cacaca;
    padding-right: 0.33em;
  }
}

.user-notes-list {
  padding-left: 1rem;
  position: relative;
}

.user-note {
  margin: 0.667rem auto 1rem;
  padding-left: 0.667rem;
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    border-left: 1px solid #8a8879;
  }
}

.user-note__attribution {
  font-size: 90%;
}

.user-note__author,
.user-note__created-datetime {
  font-weight: 300;
  font-style: normal;
}

.user-note__body {
  color: #6d6c66;
  margin: 0 auto 0.33rem;
  position: relative;

  &:first-of-type {
    margin-top: 0;
  }
}
