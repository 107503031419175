.pagination {
  a {
    opacity: 0.6;
  }

  .active {
    opacity: 1;
    border-bottom: 1px solid $base;
  }
}
