.countryCard {
  background-color: $white;
  height: 100%;

  input[type='number'] {
    max-width: ms(8);
    padding: ms(-4);
  }
}

.countryCard__selection_count {
  color: $base;
  margin-left: auto;
}

.countryCard__collapsible-content {
  border-top: 1px solid $keyline;
}
