@mixin respond-min($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin respond-max($width) {
  @media (max-width: $width) {
    @content;
  }
}
