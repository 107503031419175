.island {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: calc(100vh - #{ms(12)});

  h1 {
    color: $darkestGrey;
  }
}

.island__dialog {
  width: 100%;
  max-width: ms(18);
  margin: 0 auto;
}

.island__logobar {
  width: 100%;
  background-color: $darkestGrey;
  text-align: center;
  padding: 15px 0;

  img {
    height: 42px;
  }
}
