.payer_allocation_header {
  background-color: $lightGrey;
  border-color: $lightGrey;
  padding: ms(0) ms(1);
}

.payer_allocation_item {
  border-left: 5px solid $keyline;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: -10px;
  transition: none;

  &:hover:not(.payer_allocation_item--disabled) {
    background-color: lighten($lightGrey, 2%);
    border-left: 5px solid $accent-blue;
  }
}

.payer_allocation_item--disabled {
  pointer-events: none;

  .payer_allocation_item__name {
    opacity: 0.5;
  }
}

.payer_allocation_item__specialism {
  border-bottom: 1px solid $keyline;
  padding: ms(1) 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }

  .flex-expand {
    max-width: 80%;
  }
}

.payer_allocation_chart_requested {
  height: 30px;
  width: auto;
  position: relative;
  border-radius: $border-radius;
  color: $darkGrey;
  padding: 5px 10px 0 5px;
  background-color: $lightGrey;

  @include respond-min($breakpoint-tablet) {
    width: 240px;
  }
}

.payer_allocation_chart_allocated {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: $accent-blue;
  color: $white;
  padding: 5px 10px 0 5px;
}

@each $name, $value in $statusMap {
  .payer_allocation_status_text--#{$name} {
    color: $value;
  }

  .payer_allocation_status_border--#{$name} {
    border-color: $value;

    &:hover {
      border-color: $value;
    }
  }
}
