// Generic stylings
form {
  margin: 0;
}

legend,
label {
  color: $darkGrey;
  display: block;
  width: 100%;
}

label {
  cursor: pointer;
  margin-bottom: ms(0);

  &.required {
    position: relative;
    &:after {
      content: '*';
      color: $danger;
      padding-left: 5px;
    }
  }
}

label,
legend {
  &.required {
    position: relative;
    &:after {
      content: '*';
      color: $danger;
      padding-left: 5px;
    }
  }
}

.label--light {
  color: $base;
}

.label--inline {
  display: inline-block;
  margin-bottom: 0;
}

input {
  &:focus {
    outline: 0;
  }
  &.with-prefix {
    padding-left: 1.7em !important;
  }
}
.input-prefix {
  position: absolute;
  padding: ms(-2) 0;
  left: 1em;
  top: 0.1em;
}

.fileInput {
  label {
    margin-bottom: 0;
  }

  input[type='file'] {
    @include sr();
  }
}

.fileInput__name {
  border-right: 1px solid $keyline;
  padding-right: ms(2);
}

.fileInput__delete {
  display: none;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='url'],
input[type='search'],
input[type='number'],
input[type='time'],
input[type='date'],
textarea,
select {
  color: $darkGrey;
  width: 100%;
  padding: ms(-2);
  line-height: inherit;
  font-weight: 300;
  border: 2px solid $keyline;
  background: $lightestGrey;
  box-shadow: none;
  border-radius: $border-radius;
  transition: all 0.3s;
  -webkit-appearance: none;

  &:hover,
  &:focus {
    border-color: $accent-blue;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      border-color: $keyline;
    }
  }

  &.is-invalid {
    border-color: $danger !important;
  }

  &.form__input_label {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $keyline;
    border-radius: 0;
    color: $darkGrey;
    padding: ms(-4) 0;

    &:hover,
    &:focus {
      border-bottom-color: $accent-blue;
    }
  }
}

input[type='checkbox'],
input[type='radio'] {
  @include sr();

  + span:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-top: -0.25em;
    width: ms(0);
    height: ms(0);
    background: $lightestGrey;
    margin-right: ms(-4);
    margin-left: 2px;
  }
}

input[type='radio'], input.radio {
  + span:before {
    border-radius: 100%;
    border: 0.3em solid $lightestGrey;
    box-shadow: 0 0 0 1px $keyline;
  }
  &:checked {
    & + span:before {
      background: $accent-blue;
      box-shadow: 0 0 0 2px $accent-blue;
    }
  }
  &:focus,
  &:hover {
    & + span:before {
      box-shadow: 0 0 0 2px $accent-blue;
    }
  }
}

input[type='checkbox']:not(.radio) {
  + span:before {
    border: 2px solid $keyline;
  }
  + span.disabled {
    filter: grayscale(100%);
  }
  &:checked {
    & + span:before {
      background-color: $accent-blue;
      border-color: $accent-blue;
      background-image: url('../../img/icons/tick.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 85%;
    }
  }
  &:focus,
  &:hover {
    & + span:before {
      border-color: $accent-blue;
    }
  }
}

input[type='number'] {
  max-width: ms(13);
}

select,
input[type='search'] {
  background-repeat: no-repeat;
  background-size: ms(0) auto;
}

input[type='search'] {
  background-position: ms(-2) center;
  background-image: url('../../img/icons/search.svg');
  padding-left: ms(5);
}

select {
  background-position: calc(100% - 0.69444444em) center;
  background-image: url('../../img/icons/arrow-down.svg');
  padding-right: ms(5);
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
  display: block;
}

::placeholder {
  color: lighten($base, 8%);
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

// Custom stylings
.switch {
  float: left;
  width: 2.4rem;
  height: 1rem;
  background: $midGrey;
  position: relative;
  overflow: visible;
  border: 2px solid transparent;
  border-radius: $border-radius;

  &,
  &:before {
    transition: all 0.2s;
    border-radius: 1.3rem;
  }

  &:before {
    background: $lightestGrey;
    border: 1px solid $lightestGrey;
    box-shadow: 0px 0px 3px 1px $midGrey;
    content: '';
    display: block;
    width: 65%;
    height: 190%;
    margin-left: -14%;
    margin-top: -15%;
  }

  + span {
    margin-left: ms(-3);
    user-select: none;
  }
}

input[type='checkbox']:not(.radio):checked {
  & + .switch {
    background: $darkGrey;
    &:before {
      background: $accent-yellow;
      box-shadow: none;
      transform: translateX(100%);
    }
  }
}

.form__input {
  ul {
    list-style: none;
    padding: 0;
  }
}

.form__input_actions {
  display: grid;
  grid-gap: ms(-2);

  label {
    margin-bottom: 0;
  }

  @include respond-min($breakpoint-tablet) {
    display: flex;
  }
}

.form__input_type {
  color: $darkGrey;
  font-weight: 700;
}

.form__input--with-actions {
  position: relative;

  input[type='text'].form__input_label {
    padding-left: ms(3);
    padding-right: ms(2);
  }
}

.form__input--with-action-and-icon {
  input[type='text'].form__input_label {
    padding-left: ms(7);
  }
}

.form__input_remove {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 100%;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    width: ms(4);
    height: 100%;
  }
}

/**
 * Not my finest work - perhaps the form spacing setup
 * should be similar between builder and respondent display?
 */
.form__group {
  .form__questions {
    padding-left: ms(0);
    border-left: 1px solid $keyline;

    @include respond-min($breakpoint-mobile) {
      padding-left: ms(4);
    }

    @include respond-min($breakpoint-desktop) {
      padding-left: ms(6);
    }

    // This is purely for respondent display sub-questions
    > section {
      padding-top: ms(6);
      padding-bottom: ms(6);

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

.form__button-link {
  background: none!important;
  border: none;
  padding: 0 !important;
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  @extend a;
}
