.banner {
  background-color: $lightGrey;
  color: $darkGrey;
  padding-top: ms(3);
  padding-bottom: ms(3);

  @include respond-min($breakpoint-desktop) {
    padding-top: ms(5);
    padding-bottom: ms(5);
  }
}

.banner__inner {
  @include respond-min($breakpoint-desktop) {
    display: flex;
    align-items: flex-end;
  }
}

.banner__title {
  align-self: center;
  float: left;
}

.banner__actions {
  @include respond-min($breakpoint-desktop) {
    float: right;
    justify-content: flex-end;
    margin-left: auto;
  }

  form {
    flex: 1 0 auto;
  }

  input[type='search'] {
    border-radius: 50px;
    flex: 1 0 auto;
    margin-right: ms(-2);
    width: auto;
  }
}
