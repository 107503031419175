$base: #8a8879;

$darkestGrey: #55534c;
$darkGrey: #6d6c66;
$midGrey: #ebebe6;
$lightGrey: #f5f5f2;
$lightestGrey: #fdfdfd;

$beige: #ecebe6;
$offWhite: #d8d7d3;
$white: #fff;
$black: #000;

$keyline: #edecea;

$accent-yellow: #f9d048;
$accent-blue: #9eb3dc;

$info: #edf1f9;
$danger: #ed6755;
$danger-light: #f8e4e1;
$success: #49c19a;
$success-light: rgba(12, 166, 120, 0.1);

// Survey status colours
$statusMap: (
  draft: #cbb29c,
  invitation_sent: $accent-yellow,
  in-review: $accent-blue,
  survey_in_progress: $accent-blue,
  survey_locked: $accent-blue,
  deployed: $success,
  invitation_accepted: $success,
  survey_accepted: $success,
  survey_completed: $success,
  completed: $success,
  invitation_declined: $danger,
  rejected: $danger,
  survey_rejected: $danger,
  survey_expired: $danger,
  template: $accent-yellow,
);

// Ledger type colours
$ledgerTypeMap: (
  deposit: $success,
  withdraw: $danger,
);
