.border-right {
  @include respond-min($breakpoint-desktop) {
    border-right: 1px solid $keyline;
  }
}

.border-left {
  @include respond-min($breakpoint-desktop) {
    border-left: 1px solid $keyline;
  }
}
